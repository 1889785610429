import React from 'react';
import { Link } from 'react-router-dom';

import back_arrow from '../svg/back_arrow.svg';
import './view-media.css';
import './thumbnail/thumbnail-grid.css';
import ContentEntry from "../ContentEntry";
import MediaType from './MediaType';

class ViewMedia extends React.Component<ContentEntry> {

    specificMedia() {
        if (this.props.type === MediaType.Image) {
            return (<img src={this.props.contentLink} alt={this.props.altText}></img>)
        } else if (this.props.type === MediaType.Video){
            // @ts-ignore
            return (<video controls autoPlay src={this.props.contentLink} alt={this.props.altText} type="video/mp4"></video>)
        }
    };

    render() {
        return (
            <>
                <div className="buttons-bar back-button-bar">
                    <Link to={'/' + this.props.parentCollection}>
                        <img className="back-arrow-button" src={back_arrow} alt="back_arrow"></img>
                    </Link>
                </div>
                <div className="viewed-media">
                    {this.specificMedia()}
                </div>
            </>
            );
        }
}

export default ViewMedia;
