import * as React from "react";
import { Link } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import './thumbnail-grid.css';
import './thumbnail-transition-group.css';
import './thumbnail-overlay.css';
import ContentEntry from '../../ContentEntry';
import Collection from '../Collection';
import MediaType from '../MediaType';

import video_lines from "../../svg/video_line.svg";
import photo_lines from "../../svg/photos_line.svg";
import tedi_lines from "../../svg/tedi_line.svg";
import video_color from "../../svg/video_color.svg";
import photo_color from "../../svg/photos_color.svg";
import tedi_color from "../../svg/tedi_color.svg";
import play_video from "../../svg/play_video.svg";

type ThumbnailGridState = {
    showType: MediaType;
    showAbout: boolean;
}

class ThumbnailGrid extends React.Component<Collection, ThumbnailGridState> {
    constructor(props: Collection) {
        super(props);

        const noVideosAreInCollection = this.props.items.filter(mediaObj => mediaObj.type === MediaType.Video).length === 0;
        var initialShowType = noVideosAreInCollection ? MediaType.Image : MediaType.Video;
        if (this.getLastTabFromCookie() !== null) {
            initialShowType = this.getLastTabFromCookie() === 'image' ? MediaType.Image : MediaType.Video;            
        }
        this.state = {
            showType: initialShowType,
            showAbout: false
        };
    }
    
    // Build a key for react
    getKey(imageType: MediaType) {
        return MediaType[imageType] + (this.state.showType === imageType);
    }

    applyFiltersToContent() {
        return this.props.items.filter(c => (c.type === this.state.showType));
    }

    renderThumbnails(contentList: ContentEntry[]) {
        return contentList
            .filter((mediaObj) => !mediaObj.hidden)
            .map((mediaObj) =>
                <Link to={'/' + mediaObj.name} key={mediaObj.name}>
                    <div className="col-thumbnail">

                        <img className="thumbnail-image" src={mediaObj.thumbnail} alt={mediaObj.name}></img>
                        {mediaObj.type === MediaType.Video && <div className="thumbnail-overlay">
                            <img src={play_video} alt="vid"></img>
                        </div>}

                    </div>
                </Link>
            )
    };

    getCookieNameForLastTab() {
        // clean the readable collection name to use as cookie key
        return (this.props.readableCollectionName + "LastTab").replace(/[^a-zA-Z]/g, "");
    }

    getLastTabFromCookie() {
        var value = document.cookie
            .split('; ')
            .find(row => row.startsWith(this.getCookieNameForLastTab()));
        if (value) {
            return value.split('=')[1];
        } else {
            return null;
        }
    }

    changeTab(newShowType: MediaType) {
        document.cookie = this.getCookieNameForLastTab() + "=" + MediaType[newShowType].toLowerCase();
        this.setState({ showType: newShowType })
    }

    render() {
        return (
            <>
                <div className="title-header">
                    <Link to={'/'} >Cinema and TV student</Link>
                </div>
                <div className="main-content">
                    <div className="buttons-bar">
                        <img className="video-filter-button"
                            onClick={() => this.changeTab(MediaType.Video)}
                            src={this.state.showType === MediaType.Video ? video_color : video_lines}
                            key={this.getKey(MediaType.Video)}
                            alt={"videos"}></img>
                        <img className="photo-filter-button"
                            onClick={() => this.changeTab(MediaType.Image)}
                            src={this.state.showType === MediaType.Image ? photo_color : photo_lines}
                            key={this.getKey(MediaType.Image)}
                            alt={"photos"}></img>
                        <p className="collection-title">{this.props.readableCollectionName}</p>
                        <img className="about-me-button"
                            onClick={() => this.setState({ showAbout: !this.state.showAbout })}
                            src={this.state.showAbout ? tedi_color : tedi_lines}
                            key={(this.state.showAbout) + 'about-me-key'}
                            alt={"about me"}></img>
                    </div>
                    <div className="thumbnail-grid-row">
                        <ReactCSSTransitionGroup
                            transitionName="fadeslide"
                            transitionAppear={true}
                            transitionAppearTimeout={1000} /* duration + delay specified in css */
                            transitionEnterTimeout={500}
                            transitionLeaveTimeout={300}>
                            {this.state.showAbout && this.renderAboutMe()}
                            {this.renderThumbnails(this.applyFiltersToContent())}
                        </ReactCSSTransitionGroup>
                    </div>
                </div>
            </>
        );
}

    renderAboutMe() {
        return (
            <div className="col-fullrow" key="about-me">
                <p>Hi, I'm Tedi. I study at New Bulgarian University </p>
                <p>You can email me at teodoranchva@gmail.com</p>
            </div>
        );
    }
};

export default ThumbnailGrid;
