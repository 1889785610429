import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ThumbnalGrid from './components/thumbnail/ThumbnailGrid';
import ViewMedia from './components/ViewMedia';
import { content } from './content';
import ContentEntry from "./ContentEntry.js";
import Collection from './components/Collection';

function App() {

  // make routes out of all media files that are in all lists of content
  const mediaRoutes = content
    .map((collection: Collection) => collection.items)
    .reduce((array1: ContentEntry[], array2: ContentEntry[]) => array1.concat(array2))
    .map((mediaObj: ContentEntry) =>
      <Route exact path={'/' + mediaObj.name} render={() => (<ViewMedia {...mediaObj} />)} key={mediaObj.name} />
    );

  const collectionRoutes = content
    .map((collection: Collection) =>
      <Route exact path={'/' + collection.path} key={collection.path} >
        <ThumbnalGrid {...collection} />
      </Route>)

  return (
    <Router>
      <div className="App">

        <Switch>
          {mediaRoutes}
          {collectionRoutes}
        </Switch>

      </div>
    </Router>
  );
}

export default App;
