import ContentEntry from './ContentEntry';
import Collection from './components/Collection';
import MediaType from './components/MediaType';

import damMachine from './thumbnails/dam-machine.png';
import brunswickMonument from './thumbnails/brunswick-monument.png';
import facade from './thumbnails/facade.png';
import peekInChurch from './thumbnails/peek-in-church.png';
import BathDocumentary from './thumbnails/bath-documentary.jpg';
import bnwCityWindows from './thumbnails/bnw-city-windows.jpg';
import bnwLookUp from './thumbnails/bnw-look-up.jpg';
import bnwLookingatYou from './thumbnails/bnw-looking-at-you.jpg';
import DoorswithinDoors from './thumbnails/doors-within-doors.jpg';
import DreamingInbnw from './thumbnails/dreaming-in-bnw.jpg';
import ExploringInbnw from './thumbnails/exploring-in-bnw.jpg'; 
import CrossedFeet from './thumbnails/crossed-feet.jpg';
import Dramatic from './thumbnails/dramatic.jpg';
import Dreamy from './thumbnails/dreamy.jpg';
import LookingatYou from './thumbnails/looking-at-you.jpg';
import LookingUp from './thumbnails/looking-up.jpg';
import ToughLook from './thumbnails/tough-look.jpg';
import IntheCity from './thumbnails/in-the-city.jpg';
import WindowtoCity from './thumbnails/window-to-city.jpg';
import CyberScare from './thumbnails/cyber-scare.png';
import ClassicStory from './thumbnails/classic-story.png';
import galaxyPizza from './thumbnails/galaxy-pizza.png';
import wiro from './thumbnails/wiro.png';
import lyingBetweenDestinations from './thumbnails/lying-between-destinations.jpg';
import runningOut from './thumbnails/running-out.jpg';
import vesiInterview from './thumbnails/vesi_interview.png';
import SoundTask from './thumbnails/zvuk_task.png';

const videoFormats = ['mp4', 'mov', 'wmv', 'avi', 'mkv', 'webm', 'ogg'];

const rawContent: Collection[] = [
    {
        path: '',
        readableCollectionName: '',
        items: [
            { name: 'doors-within-doors', contentFormat: 'jpg', thumbnail: DoorswithinDoors },
            { name: 'exploring-in-bnw', contentFormat: 'jpg', thumbnail: ExploringInbnw },
            { name: 'wiro', contentFormat: 'mp4', thumbnail: wiro, altText: 'wireless charger advert' },
            { name: 'cyber-scare', contentFormat: 'mp4', thumbnail: CyberScare },
            { name: 'bath-documentary', contentFormat: 'mp4', thumbnail: BathDocumentary },
            { name: 'lying-between-destinations', contentFormat: 'mp4', thumbnail: lyingBetweenDestinations }, 
            { name: 'running-out', contentFormat: 'mp4', thumbnail: runningOut },

            { name: 'window-to-city', contentFormat: 'jpg', thumbnail: WindowtoCity, altText: 'Veliko Turnovo sunset castle', hidden: true },
            { name: 'in-the-city', contentFormat: 'jpg', thumbnail: IntheCity, hidden: true },
            { name: 'bnw-city-windows', contentFormat: 'jpg', thumbnail: bnwCityWindows, hidden: true},
            { name: 'bnw-look-up', contentFormat: 'jpg', thumbnail: bnwLookUp, hidden: true },
            { name: 'bnw-looking-at-you', contentFormat: 'jpg', thumbnail: bnwLookingatYou, hidden: true },
            { name: 'dreaming-in-bnw', contentFormat: 'jpg', thumbnail: DreamingInbnw, hidden: true },
            { name: 'galaxy-pizza', contentFormat: 'mp4', thumbnail: galaxyPizza, hidden: true },
            { name: 'classic-story', contentFormat: 'mp4', thumbnail: ClassicStory, hidden: true },
            { name: 'crossed-feet', contentFormat: 'jpg', thumbnail: CrossedFeet, hidden: true },
            { name: 'dramatic', contentFormat: 'jpg', thumbnail: Dramatic, hidden: true },
            { name: 'dreamy', contentFormat: 'jpg', thumbnail: Dreamy, hidden: true },
            { name: 'looking-at-you', contentFormat: 'jpg', thumbnail: LookingatYou, hidden: true },
            { name: 'looking-up', contentFormat: 'jpg', thumbnail: LookingUp, hidden: true },
            { name: 'tough-look', contentFormat: 'jpg', thumbnail: ToughLook, hidden: true },
            { name: 'peek-in-church', contentFormat: 'jpeg', thumbnail: peekInChurch, hidden: true },
            { name: 'facade', contentFormat: 'jpeg', thumbnail: facade, hidden: true },
            { name: 'Kursova-Zadacha-2-Teodora-Nancheva-F100491', contentFormat: 'mov', thumbnail: vesiInterview, hidden: true }, 
            { name: 'Kursova-Zadacha-1-Klip-1-2-3-Teodora-Nancheva-F100491', contentFormat: 'mov', thumbnail: SoundTask, hidden: true }
        ]
    },
    {
        path: 'genevaWalk',
        readableCollectionName: 'A walk in Geneva',
        items: [
            { name: 'dam-machine', contentFormat: 'jpeg', thumbnail: damMachine },
            { name: 'brunswick-monument', contentFormat: 'jpeg', thumbnail: brunswickMonument }
        ]
    }
];
    
/*
    1. Make a thumbnail image by cropping and scaling to 480x270 pixels.
    2. Use imagecompressor.com to compress the thumbnail.
    
    in github:
    3. Put thumbnail in the folder src/thumbnails.
    4. Import thumbnail by copying above imports and changing the name and path to the new file.
    5. Add a new line above in rawContent with exactly the name you want for the real file, exact case-sensitive format and the import name for the thumbnail.
       Optionally add "hidden: true"; or "altText: 'text'".

    in AWS S3:
    6. Upload real content image/video in AWS S3 with exactly the same name and case-sensitive format.


    16:9 thumbnail sizes to crop to:
        480x270
        960x540
        1920x1080
        2400x1350
        2880x1620
        3360x1890
        3840x2160
*/

function prepareContent(mediaObj: ContentEntry, parentCollection: string) {
    mediaObj.contentLink = 'https://teodora-nancheva.s3.eu-central-1.amazonaws.com/' + mediaObj.name + '.' + mediaObj.contentFormat;
    var contentFormatToLower = mediaObj.contentFormat.toLowerCase();
    mediaObj.type = videoFormats.includes(contentFormatToLower) ? MediaType.Video : MediaType.Image;
    mediaObj.parentCollection = parentCollection;
    mediaObj.altText = mediaObj.altText ? mediaObj.altText : mediaObj.name;
    return mediaObj;
}

var content: Collection[] = [];

for (const collection of rawContent) {
    var newCollection = collection;
    newCollection.items = collection.items.map(file => prepareContent(file, collection.path));
    content.push(newCollection);
}

export { content };
